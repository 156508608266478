import React, { FunctionComponent, useState } from 'react';
import { IntlProvider } from 'react-intl';
import langRo from '../messages/ro.json';

type Props = {
  children: React.ReactNode
};

export const IntlContext = React.createContext({ locale: 'en', selectLanguage: (lang: string) => {} });

const _messages = langRo;
const _locale = 'ro';

const IntlProviderWrapper: FunctionComponent<Props> = ({ children }) => {
  const [locale, setLocale] = useState(_locale);
  const [messages, setMessages] = useState(_messages);

  const selectLanguage = (lang: string) => {
    if (lang === 'ro') {
      setLocale('ro');
      setMessages(langRo);
    } else {
      setLocale('en');
      setMessages({} as any);
    }
  }

  return (
    <IntlContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider locale={locale} messages={messages} defaultLocale={ 'en' }>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

export default IntlProviderWrapper;
