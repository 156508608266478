import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type NotFoundPageProps = Record<string, never>;

const NotFoundPage: FunctionComponent<NotFoundPageProps> = () => (
  <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
    <h1>404</h1>
    <h3 className="mt-5"> Oops. This page has gone missing.</h3>
    <h6 className="mb-5"> You may have mistyped the address or the page may have moved.</h6>
    <Link to={ '/' } className="btn btn-primary mt-3 d-inline-block">
      Back to Home
    </Link>
  </div>
);

export default NotFoundPage;
