import React from "react";
import {Col, Container, Row} from "reactstrap";
import logo from "../media/img/logo3.svg";
import flower1 from "../media/img/flower1.png";
import flower2 from "../media/img/flower2.png";

const Menu = () => {
  return (
    <>
      <div className={'hero position-relative'}>
        <Container fluid className={'content'}>
          <Row>
            <Col className={'text-center'} md={12}>
              <a href={'/'}><img src={logo} className={'img-fluid logo'}/></a>
            </Col>
          </Row>
        </Container>
        <div className={'position-absolute top-0 start-0 flower d-xs-none'}>
          <img src={flower1} className={'img-fluid'}/>
        </div>
        <div className={'position-absolute top-0 end-0 flower d-xs-none'}>
          <img src={flower2} className={'img-fluid'}/>
        </div>
      </div>
      <div className={'text-center p-5'}>
        <div className={'my-5'}>
          <h2>Gustare</h2>
          <p>Trilogie de curcan</p>
          <p>Duet de salau si somon in alge marine si sos dulce-picant</p>
          <p>Sushi cu somon fume si avocado, crevete panko</p>
          <p>Cheesecake cu somon fume, creveti aromatizati in usturoi si ghimbir</p>
          <p>Muschiulet de porc cu pruna uscata</p>
          <p>Branza de burduf in crusta de Panko cu ou de prepelita, rosie cherry si busuioc</p>
          <p>Rulou de pui cu spanac si rosii uscate invelit in panko</p>
          <p>Somon Gravalax</p>
          <p>Rulou de pui cu legume si chives</p>
          <p>Cosulet cu piept de rata si mix de salata</p>
          <p>Salata de caracatita</p>
          <p>Tartar de ton cu mango si avocado</p>
        </div>


        <div className={'my-5'}>
          <h2>Peste</h2>
          <p>Biban de mare cu mousse de somon si sos de ardei copt, garnitura: risotto cu sparanghel
            si parmezan</p>

          <div className={'my-3'}>Sau</div>

          <p>Duo de somon la cuptor cu sos de mustar, rulou de pastrav cu legume si garnitura de
            orez brun cu unt si parmezan, baby spanac si rosii cherry</p>
        </div>

        <div className={'my-5'}>
          <h2>Sarmale</h2>
          <p>Sarmale in foi de varza servite cu mamaliguta, smantana, costita crocanta, rosie
            concase si ardei iute - fierte la ceaun la foc de lemn</p>
        </div>


        <div className={'my-5'}>

          <h2>Fel Principal</h2>
          <p>Muschi de vita sous vide cu sos de hribi si legume coapte</p>
          <div className={'my-3'}>Sau</div>
          <p>Cotlet de porc in sos de vin alb, piept de pui umplut cu branza feta si rosii uscate si
            legume la gratar</p>
          <div className={'my-3'}>Sau</div>
          <p>Pulpa de rata sous vide cu sos de fructe de padure si garnitura de varza rosie</p>
        </div>


        <div className={'my-5'}>

          <h2>Desert</h2>
          <p>Tort: French Kiss (blat cacao, mousse de ciocolata alba belgiana si fructe de padure,
            mousse de
            ciocolata neagra belgiana)</p>

          <p>Candy bar</p>
          <p>Donut bar</p>
          <p>Cheese bar</p>
          <p>Fantana de ciocolata cu fructe</p>
        </div>


        <div className={'my-5'}>
          <h2>Lemonade bar</h2>
          <p>Diferite sortimente</p>
        </div>

        <div className={'my-5'}>
          <h2>Bauturi alcoolice</h2>
          <p><b>Whiskey:</b> Chivas Regal 18 years, Chivas Regal 12 years, Johnnie Walker Red Label
          </p>
          <p><b>Vodka:</b> Smirnoff, Beluga, Grey Goose</p>
          <p><b>Vin:</b> Purcari Rose, Chardonnay, Rara Neagra</p>
          <p>Curvoisier VB</p>
          <p>Campari Orange</p>
          <p>Crema de whisky Baileys</p>
          <p>Cinzano</p>
          <p>Bere Carlsberg</p>
          <p>Bere fara alcool Tuborg</p>
        </div>

        <div className={'my-5'}>
          <h2>Cocktail Bar</h2>

          <p><b>Alcoholic Cocktails:</b>
            <ul style={{listStyle: 'none'}}>
              <li><b>Aperol Spritz</b> (aperol, prosecco, apa minerala, portocala)</li>
              <li><b>Hugo</b> (prosecco, apa minerala, sirop soc, lime, menta)</li>
              <li><b>Blueberry High Life</b> (vodka, piure de coacaze, menta, lime, suc de
                cranberry)
              </li>
              <li><b>Blue Lagoon</b> (vodka, blue curacao, limonada)</li>
              <li><b>Bramble</b> (gin, piure de coacaze, suc de lamaie)</li>
              <li><b>Pineapple Mint Julep</b> (whiskey, menta, sirop de zahar, suc de ananas)</li>
              <li><b>Sunset Boulevard</b> (vodka, rom, gin, triplu sec, suc dulce-acrisor,
                grenadine,
                limonada)
              </li>
              <li><b>Mojito</b> (rom, zahar brun, apa minerala, menta și lime)</li>
              <li><b>Cosmopolitan</b> (vodka, triplu sec, suc de cranberry)</li>
              <li><b>Tequila Sunrise & Sunset</b> (tequila, suc de portocale, grenadine)</li>
              <li><b>Pina Colada</b> (rom, sirop de cocos și suc de ananas)</li>
              <li><b>Cuba Libre</b> (rom, Coca Cola)</li>
              <li><b>Campari Orange</b> (campari, suc de portocale)</li>
              <li><b>Sex On The Beach</b> (vodka, sirop piersici, grenadine, suc portocale, piersici
                si
                ananas)
              </li>
              <li><b>Whiskey Cola</b> (whiskey, Coca Cola)</li>
              <li><b>Vodka Orange</b> (vodka, suc de portocale)</li>
              <li><b>Gin Tonic</b> (gin, apa tonica)</li>
              <li><b>Americano</b> (Cinzano, Campari, apa minerala)</li>
            </ul>
          </p>

          <p><b>Shot-uri:</b>
            <ul style={{listStyle: 'none'}}>
              <li><b>Whiskey Sour</b> (whiskey, fresh lamaie și sirop de zahar)</li>
              <li><b>Big Shot</b> (rom, triple sec, suc ananas)</li>
              <li><b>Melot Shot</b> (vodka, piure melon, fresh lamaie)</li>
              <li><b>Scooby Doo</b> (rom, sirop cocos, suc ananas, lapte)</li>
              <li><b>Purple Hooter</b> (vodka, raspberry piure, fresh lamaie)</li>
              <li><b>Blue/Red/Yellow Kamikaze</b> (vodka, triplu sec, suc de lime)</li>
            </ul>
          </p>

          <p><b>Non alcoholic cocktails:</b>
            <ul style={{listStyle: 'none'}}>
              <li><b>Green Apple</b> (lime, sirop de zahar și suc de mere)</li>
              <li><b>Coconut Kiss</b> (suc de anans, sirop de cocos, suc de cranberry si grenadine)
              </li>
              <li><b>Cinderella</b> (suc de portocale, suc de ananas, suc de lamaie și grenadine)
              </li>
              <li><b>Pelikan</b> (suc de ananas, suc de grapefruit, sirop de cocos, lime și
                grenadine)
              </li>
              <li><b>Nada Colada</b> (suc de anans, sirop de cocos, crema de lapte)</li>
              <li><b>Cranberry Cooler</b> (suc de cranberry, lime, suc de lămâie si sirop de zahăr)
              </li>
            </ul>
          </p>
        </div>


        <div className={'my-5'}>
          <h2>Coffee Bar</h2>
          <ul style={{listStyle: 'none'}}>
            <li><b>Almond Iced Coffee</b> (espresso, sirop migdale, sirop ciocolata, lapte, gheata,
              frisca)
            </li>
            <li><b>French Vanilla Iced Coffee</b> (espresso, sirop vanilie, lapte, gheata, frisca)
            </li>
            <li><b>Caramel Machiatto Coffee</b> (espresso, sirop caramel, sirop ciocolata, crema de
              lapte,
              gheata, frisca)
            </li>
          </ul>
        </div>

        <div className={'my-5'}>
          <h2>Espresso Bar</h2>
          <p>Espresso</p>
          <p>Cappuccino</p>
          <p>Ristretto</p>
          <p>Macchiato</p>
          <p>Caffe Latte</p>
        </div>
      </div>
    </>
  )
}

export {
  Menu,
}
