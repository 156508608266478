import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Countdown from 'react-countdown';
import { Footer } from '../components';

import flower1 from '../media/img/flower1.png';
import flower2 from '../media/img/flower2.png';
import shape5 from '../media/img/shape5.png';
import shape6 from '../media/img/shape6.png';
import logo from '../media/img/logo4.svg';
import whereWeMet from '../media/img/where_me_met.png';
import firstDate from '../media/img/first_date.png';
import longWait from '../media/img/long_wait.png';
import birthImg from '../media/img/birth.png';
import marriageProposal from '../media/img/marriageProposal.png';
import wedding from '../media/img/wedding.png';
import surpriseImg from '../media/img/surprise.png';
import sectionTitle from '../media/img/section-title2.png';

const Home = () => {
  return (
    <>
      <div className={'hero position-relative'}>
        <Container fluid className={'content'}>
          <Row>
            <Col className={'text-center'} md={12}>
              <a href={'/'}><img src={logo} className={'img-fluid logo'}/></a>
            </Col>
          </Row>
          <Row>
            <Col className={'text-center text-md-end py-2'} md={{ size: 3, offset: 2 }} xs={12}>
              <div>
                <img src={shape6} className={'img-fluid'}/>
              </div>
              <h3 className={'mt-5'}>Cezara Iulia</h3>
              <p className={'align-top'}>
                <FormattedMessage id={'app.woman.description'}
                                  defaultMessage={'Sometimes it\'s the princess who kills the dragon and saves the prince - Samuel E. Lowe'}/>
              </p>
              <div className={'social-media fs-4'}>
                <a href={'https://www.facebook.com/cezara.cez.14'} rel={'noreferrer noopener'}
                   target={'_blank'} className={'d-inline-block pe-2'}>
                  <FontAwesomeIcon icon={[ 'fab', 'facebook' ]}/>
                </a>
                <a href={'#'} rel={'noreferrer noopener'} target={'_blank'}
                   className={'d-inline-block px-4'}>
                  <FontAwesomeIcon icon={[ 'fab', 'twitter' ]}/>
                </a>
                <a href={'https://www.instagram.com/cezara.ioan/'} rel={'noreferrer noopener'}
                   target={'_blank'} className={'d-inline-block ps-2'}>
                  <FontAwesomeIcon icon={[ 'fab', 'instagram' ]}/>
                </a>
              </div>
            </Col>
            <Col md={2}>

            </Col>
            <Col className={'text-center text-md-start py-2'} md={{ size: 3, offset: 0 }} xs={12}>
              <div>
                <img src={shape5} className={'img-fluid'}/>
              </div>
              <h3 className={'mt-5'}>Razvan Mihai</h3>
              <p className={'align-top'}>
                <FormattedMessage id={'app.man.description'}
                                  defaultMessage={'Men want the same thing from their underwear that they want from women: a little bit of support, and a little bit of freedom - Jerry Seinfeld'}/>
              </p>
              <div className={'social-media fs-4'}>
                <a href={'https://www.facebook.com/razvanceana/'} rel={'noreferrer noopener'}
                   target={'_blank'} className={'d-inline-block pe-2'}>
                  <FontAwesomeIcon icon={[ 'fab', 'facebook' ]}/>
                </a>
                <a href={'https://twitter.com/xterr24'} rel={'noreferrer noopener'}
                   target={'_blank'} className={'d-inline-block px-4'}>
                  <FontAwesomeIcon icon={[ 'fab', 'twitter' ]}/>
                </a>
                <a href={'https://www.instagram.com/razvanceana/'} rel={'noreferrer noopener'}
                   target={'_blank'} className={'d-inline-block ps-2'}>
                  <FontAwesomeIcon icon={[ 'fab', 'instagram' ]}/>
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={'text-center'} md={12}>
              <div>
                <img src={shape6} className={'img-fluid'}/>
                <img src={shape5} className={'img-fluid'}/>
              </div>
              <h3 className={'mt-5'}>Erwin Dominic</h3>
              <p className={'align-top'}>
                <FormattedMessage id={'app.baby.description'}
                                  defaultMessage={'In the end, it\'s not the years in your life that count. It\'s the life in your years - Abraham Lincoln'}/>
              </p>
            </Col>
          </Row>
        </Container>
        <div className={'position-absolute top-0 start-0 flower d-xs-none'}>
          <img src={flower1} className={'img-fluid'}/>
        </div>
        <div className={'position-absolute top-0 end-0 flower d-xs-none'}>
          <img src={flower2} className={'img-fluid'}/>
        </div>
      </div>
      <Container className={'clock-grids my-5'}>
        <Row>
          <Col className={'d-flex align-items-center justify-content-between text-center'}>
            <Countdown date={Date.UTC(2024, 10, 16, 15, 0, 0)}
                       renderer={({ days, hours, minutes, seconds }) => {
                         return (
                           <>
                             <div className={'box'}>
                               <div>
                                 <div className={'time mb-2'}>{days}</div>
                                 <span><FormattedMessage id={'app.countdown.days'}
                                                         defaultMessage={'Days'}/></span>
                               </div>
                             </div>
                             <div className={'box'}>
                               <div>
                                 <div className={'time mb-2'}>{hours}</div>
                                 <span><FormattedMessage id={'app.countdown.hours'}
                                                         defaultMessage={'Hours'}/></span>
                               </div>
                             </div>
                             <div className={'box'}>
                               <div>
                                 <div className={'time mb-2'}>{minutes}</div>
                                 <span><FormattedMessage id={'app.countdown.minutes'}
                                                         defaultMessage={'Mins'}/></span>
                               </div>
                             </div>
                             <div className={'box'}>
                               <div>
                                 <div className={'time mb-2'}>{seconds}</div>
                                 <span><FormattedMessage id={'app.countdown.seconds'}
                                                         defaultMessage={'Secs'}/></span>
                               </div>
                             </div>
                           </>
                         )
                       }}/>
          </Col>
        </Row>
      </Container>
      <Container className={'my-5 section-title'}>
        <Row>
          <Col className={'text-center py-5'}>
            <img src={sectionTitle}/>
            <h2 className={'my-4'}><FormattedMessage id={'app.timeline'}
                                                     defaultMessage={'Our timeline'}/></h2>
            <div className="section-title-line">
              <div className="round-ball"></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={'my-5'}>
        <Row>
          <Col xs={12}>
            <div className={'timeline'}>
              <div className={'round-shape'}></div>
              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-end pe-md-5 py-1">
                    <h3><FormattedMessage id={'app.timeline.firstTimeWeMet.title'}
                                          defaultMessage={'First time we met'}/></h3>
                    <span className="date">Nov / 2016</span>
                    <p><FormattedMessage id={'app.timeline.firstTimeWeMet.text'}
                                         defaultMessage={'We met in Silver Church (for those who don\'t know what Silver Church is, no, it\'s not a church. but a club). Our dear club where we laughed, we cried, we had fun, we got angry but where we found our soul mate. We both admit that there was a spark from the beginning'}/>
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-start ps-md-5">
                    <img src={whereWeMet}/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-end pe-md-5">
                    <img src={firstDate}/>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-start ps-md-5 py-1">
                    <h3><FormattedMessage id={'app.timeline.firstDate.title'}
                                          defaultMessage={'First date'}/></h3>
                    <span className="date">1/Apr/2017</span>
                    <p><FormattedMessage id={'app.timeline.firstDate.text'}
                                         defaultMessage={'It is no longer a secret for anyone that our first kiss was initiated by Cezara on the song Ed Sheeran - Shape of You in Silver Church'}/>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-end pe-md-5 py-1">
                    <h3><FormattedMessage id={'app.timeline.loongWait.title'}
                                          defaultMessage={'Loooong wait...'}/></h3>
                    <span className="date">2017 - 2022</span>
                    <p><FormattedMessage id={'app.timeline.loongWait.text'}
                                         defaultMessage={'We went through a lot together during our relationship. We laughed, we cried, we had fun, we even argued, but we never separated and always knew how to solve our problems'}/>
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-start ps-md-5">
                    <img src={longWait}/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-end pe-md-5">
                    <img src={marriageProposal}/>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-start ps-md-5 py-1">
                    <h3><FormattedMessage id={'app.timeline.marriageProposal.title'}
                                          defaultMessage={'Marriage proposal'}/></h3>
                    <span className="date">16/Jul/2022</span>
                    <p><FormattedMessage id={'app.timeline.marriageProposal.text'}
                                         defaultMessage={'Well, there wasn\'t necessarily a request like in movies with Razvan on his knees in a fairytale landscape. The marriage proposals appeared already in the second year of the relationship, but without a ring, more in the form of jokes and wrapped in love as confirmations of the stability of the relationship. In 2022, after a night of partying, we arrived home and discussed the wedding until 5 in the morning and look, it worked and honestly for us this thing was much more interesting in this form'}/>
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-end pe-md-5">
                    <h3><FormattedMessage id={'app.timeline.wedding.title'}
                                          defaultMessage={'Wedding. Finally, some would say !!!'}/>
                    </h3>
                    <span className="date">21/Jul/2023</span>
                    <p><FormattedMessage id={'app.timeline.wedding.text'}
                                         defaultMessage={'This step is an important one for us. But we know that what we have built together so far is much more important'}/>
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-start ps-md-5">
                    <img src={wedding}/>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-start ps-md-5">
                    <img src={surpriseImg}/>
                  </div>
                </Col>

                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-end pe-md-5">
                    <h3><FormattedMessage id={'app.timeline.pregnancy.title'}
                                          defaultMessage={'Surprise! We are pregnant'}/>
                    </h3>
                    <span className="date">15/nov/2023</span>
                    <p><FormattedMessage id={'app.timeline.pregnancy.text'}
                                         defaultMessage={'In 2023, we received the most wonderful news of our lives. We are expecting and eagerly looking forward to meeting our baby'}/>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="story-text text-center text-md-end pe-md-5">
                    <h3><FormattedMessage id={'app.timeline.birth.title'}
                                          defaultMessage={'Surprise! We are pregnant'}/>
                    </h3>
                    <span className="date">06/aug/2024</span>
                    <p><FormattedMessage id={'app.timeline.birth.text'}
                                         defaultMessage={'In 2024, we made the most important upgrade of our lives: we became parents! We brought into the world a baby full of personality, who immediately took over as the CEO of the family. Since his arrival, our days have been a marathon of diapers, smiles, and sleepless nights, but we\'ve never been happier. Our lives have completely changed — and for the first time, we\'re glad that we\'re no longer the center of attention!'}/>
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12} className={'py-5'}>
                  <div className="image-holder ps-md-5 py-1 text-center text-md-start ps-md-5">
                    <img src={birthImg}/>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col className={'text-center py-5'}>
                <FormattedMessage id={'app.timeline.to_be_continued'}
                                  defaultMessage={'To be continued...'}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className={'my-5 section-title'}>
        <Row>
          <Col className={'text-center py-5'}>
            <img src={sectionTitle}/>
            <h2 className={'my-4'}><FormattedMessage id={'app.timeline.a_new_adventure_begins'}
                                                     defaultMessage={'A new adventure begins'}/>
            </h2>
            <div className="section-title-line">
              <div className="round-ball"></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={'events'}>
        <Row className={'text-center'}>
          <Col sm={6}>
            <div className={'event-item p-3'}>
              <div className={'event-text p-3'}>
                <h2><FormattedMessage id={'app.events.baptism_ceremony'}
                                      defaultMessage={'Baptism'}/></h2>
                <p className={'fs-4'}>
                  <FormattedDate value={'2024-11-16'} weekday="long" month="short" year={'numeric'}
                                 day={'numeric'}/>
                  <br/>
                  15:00 - 16:00
                </p>
                <p><FormattedMessage id={'app.events.baptism_church_name'}
                                     defaultMessage={'Saint Michael and Gabriel Church, Petresti'}/>
                </p>
                <p>
                  <a
                    href={"https://www.google.com/maps/place/%22Saint+Michael+and+Gabriel%22+Church+in+Petre%C8%99ti/@44.6056861,26.0637252,16.17z/data=!4m14!1m7!3m6!1s0x40b21bd1c5ad06d1:0x28895fca2a660157!2s%22Saint+Michael+and+Gabriel%22+Church+in+Petre%C8%99ti!8m2!3d44.6075717!4d26.0631537!16s%2Fg%2F11b7q4h2pz!3m5!1s0x40b21bd1c5ad06d1:0x28895fca2a660157!8m2!3d44.6075717!4d26.0631537!16s%2Fg%2F11b7q4h2pz?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"}
                    target={"_blank"}>
                    <FormattedMessage id={'app.events.baptism_church_map'} defaultMessage={'Map'}/>
                  </a>
                </p>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className={'event-item p-3'}>
              <div className={'event-text p-3'}>
                <h2><FormattedMessage id={'app.events.baptism_party'}
                                      defaultMessage={'Dominic\'s Party'}/></h2>
                <p className={'fs-4'}>
                  <FormattedDate value={'2024-11-16'} weekday="long" month="short" year={'numeric'}
                                 day={'numeric'}/>
                  <br/>
                  18:00 - 05:00
                </p>
                <p>Domeniile Saftica, Ador by the pool</p>
                <p>
                  <a
                    href={"https://www.google.com/maps/place/%22S%C4%83ftica%22+Domains/@44.6212086,26.0702735,20.02z/data=!4m10!1m2!2m1!1sdomeniile+saftica!3m6!1s0x40b21978e1e00073:0xf45a6ee2814a5c2d!8m2!3d44.6210827!4d26.0696027!15sChFkb21lbmlpbGUgc2FmdGljYVoTIhFkb21lbmlpbGUgc2FmdGljYZIBC2V2ZW50X3ZlbnVl4AEA!16s%2Fg%2F11r8d87hv?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"}
                    target={"_blank"}>
                    <FormattedMessage id={'app.events.baptism_church_map'} defaultMessage={'Map'}/>
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'text-center mt-5 opacity-25'}>
          <Col sm={4}>
            <FontAwesomeIcon icon={[ 'fas', 'check' ]} size={'3x'}/>
            <div className={'event-item p-3'}>
              <div className={'event-text p-3'}>
                <h2><FormattedMessage id={'app.events.civil_ceremony'}
                                      defaultMessage={'Civil Ceremony'}/></h2>
                <p className={'fs-4'}>
                  <FormattedDate value={'2023-07-21'} weekday="long" month="short" year={'numeric'}
                                 day={'numeric'}/>
                  <br/>
                  19:30 - 19:50
                </p>
                <p>Salon Du Mariage - Saftica Domains</p>
                <p>Toujours L’amour by the pool</p>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <FontAwesomeIcon icon={[ 'fas', 'check' ]} size={'3x'}/>
            <div className={'event-item p-3'}>
              <div className={'event-text p-3'}>
                <h2><FormattedMessage id={'app.events.religious_ceremony'}
                                      defaultMessage={'Religious Ceremony'}/></h2>
                <p className={'fs-4'}>
                  <FormattedDate value={'2023-07-21'} weekday="long" month="short" year={'numeric'}
                                 day={'numeric'}/>
                  <br/>
                  20:00 - 21:00
                </p>
                <p>Salon Du Mariage - Saftica Domains</p>
                <p>Toujours L’amour by the pool</p>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <FontAwesomeIcon icon={[ 'fas', 'check' ]} size={'3x'}/>
            <div className={'event-item p-3'}>
              <div className={'event-text p-3'}>
                <h2><FormattedMessage id={'app.events.wedding_party'}
                                      defaultMessage={'Wedding Party'}/></h2>
                <p className={'fs-4'}>
                  <FormattedDate value={'2023-07-21'} weekday="long" month="short" year={'numeric'}
                                 day={'numeric'}/>
                  <br/> 21:00 - 05:00
                </p>
                <p>Salon Du Mariage - Saftica Domains</p>
                <p>Toujours L’amour by the pool and in the pool</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={'pt-5'}>

        <iframe
          id="JotFormIFrame-242426414113344"
          title="Confirmare botez Dominic"
          onLoad={() => {
            window.parent.scrollTo(0, 0)
          }}
          allowTransparency={true}
          allow="geolocation; microphone; camera; fullscreen"
          src="https://form.jotform.com/242426414113344"
          frameBorder="0"
          style={{ minWidth: '100%', width: '100%', height: '1500px', border: 'none' }}
          scrolling="no"
        >
        </iframe>
        <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
        <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-242426414113344']",
          "https://form.jotform.com/")
        </script>

      </Container>
      <Footer/>
    </>
  );
}

export default Home;
