import React, {FunctionComponent} from 'react';
import {Button, Container} from 'reactstrap';
import {IntlContext} from './IntlProviderWrapper';

type Props = Record<string, unknown>;

const Footer: FunctionComponent<Props> = () => {
  const {locale, selectLanguage} = React.useContext(IntlContext);

  return (
    <footer className={'p-5 mt-5'}>
      <Container fluid>
        <div className={'text-center'}>
          <Button outline active={locale === 'ro'} onClick={() => selectLanguage('ro')}
                  className={'mx-4'}>Română</Button>
          <Button outline active={locale === 'en'} onClick={() => selectLanguage('en')}
                  className={'mx-4'}>English</Button>
        </div>
        <div className={'text-center mt-5'}>
          Powered by <a href={'https://xterr.design'} target={'_blank'}>Xterr
          Design</a> © {new Date().getFullYear()}
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
