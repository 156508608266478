import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, Menu, NotFoundPage } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={ <Home/> }/>
      <Route path="/meniu" element={ <Menu/> }/>
      <Route path="*" element={ <NotFoundPage/> }/>
    </Routes>
  );
}

export default App;
